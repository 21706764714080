// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/Kiuny.jpg";
import team2 from "assets/images/Rob.jpeg";
import team3 from "assets/images/ivana-squares.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Meet Our Expert Team
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Our team is dedicated to providing exceptional co-hosting and property management services.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Kiuny Perez"
                position={{ color: "info", label: "Co-Host Manager" }}
                description="Kiuny is an expert in maximizing rental income and ensuring guest satisfaction."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Roberto Sanders"
                position={{ color: "info", label: "Co-Host Manager" }}
                description="Roberto oversees all operations, ensuring everything runs smoothly."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={12} display="flex" justifyContent="center">
            <MKBox mb={1} width={{ xs: "100%", md: "50%" }}>
              <HorizontalTeamCard
                image={team3}
                name="Adriana Amaral"
                position={{ color: "info", label: "Cleaning Services" }}
                description="Adriana ensures all guests have a pleasant and memorable stay."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
