// routes.js
import AboutUs from "layouts/pages/company/about-us";
import ContactUs from "layouts/pages/support/contact-us";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import Icon from "@mui/material/Icon";

const routes = [
  {
    name: "Home",
    route: "/",
    component: <AboutUs />,
  },
  {
    name: "Accounts",
    icon: <Icon>contacts</Icon>,
    collapse: [
      {
        name: "Sign In",
        route: "/authentication/sign-in/illustration",
        component: <SignInIllustration />,
      },
    ],
  },
  {
    name: "Support",
    icon: <Icon>dashboard</Icon>,
    collapse: [
      {
        name: "Contact Us",
        route: "/pages/support/contact-us",
        component: <ContactUs />,
      },
    ],
  },
];

export default routes;
