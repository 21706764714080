// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

// Images
import airbnb from "assets/images/logos/gray-logos/airbnb-2-logo-svgrepo-com.svg";
import guesty from "assets/images/logos/gray-logos/guesty-inc-logo-vector.svg";
import vrbo from "assets/images/logos/gray-logos/Vrbo-Logo.wine.svg";
import pricelabs from "assets/images/logos/gray-logos/pricelabs.svg";
import hospitable from "assets/images/logos/gray-logos/hospitable.png";
import hostaway from "assets/images/logos/gray-logos/hostaway.svg";




function Featuring() {
  return (
    <MKBox component="section" pt={3} pb={8}>
      <Container>
        <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ mb: 12 }}>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={airbnb} alt="airbnb" width="100%" sx={{ mx: "auto" }} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={guesty} alt="guesty" width="100%" sx={{ mx: "auto" }} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={vrbo} alt="vrbo" width="100%" sx={{ mx: "auto" }} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={pricelabs} alt="Pricelabs" width="100%" sx={{ mx: "auto" }} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={hospitable} alt="Hospitable" width="100%" sx={{ mx: "auto" }} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={hostaway} alt="hostaway" width="100%" sx={{ mx: "auto" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={1300}
              separator=","
              prefix="Over "
              title="Nights Booked"
              description="In the last 365 days between our clients"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={260000}
              separator=","
              prefix="$ "
              suffix="+"
              title="Total Booking Revenue"
              description="Total booking value generated for our clients in the last year"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={24}
              suffix="/7"
              title="Support"
              description="Our team provides round-the-clock support for all properties"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Featuring;
